import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useWatermelonSynchronize } from '../../model/hooks';

export default function Main({ children }) {
    const theme = useTheme();
    const synchronize = useWatermelonSynchronize();

    useEffect(() => {
        setTimeout(() => {
            synchronize();
        }, 100);
    }, [synchronize]);

    return (
        <Box sx={{
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        }}>
            <Box sx={{ ...theme.mixins.toolbar }} ></Box>
            <Box component="main" sx={{
                display: "flex",
                flexGrow: 1,
            }}>
                {children}
            </Box>
        </Box>
    );
}
