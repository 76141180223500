import { Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import SiteProviderAttribution from './site-attribution';
import SiteLastModification from './site-last-modification';
import SiteMap from './site-map';
import SiteOrientations from './site-orientations';

export default function SiteOverview({ site }) {
    return (
        <Card sx={{ /*maxWidth: 345*/ }}>
            <CardHeader
                title={site.name}
                subheader={site.shortName}
                action={
                    <Fragment>
                        <SiteLastModification sx={{ mr: 2 }} date={site.lastModified} />
                        <SiteProviderAttribution provider={site.provider} id={site.providerId} />
                    </Fragment>
                }
            />

            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <Typography variant="body1" color="text.secondary">
                            {site.descriptionText}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Wind conditions
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {site.descriptionWind}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Hazards
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {site.descriptionHazards}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Restrictions
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {site.descriptionRestrictions}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {site.regulatory_information_panel && (
                            <Typography variant="body1" color="text.secondary">
                                Information panel is present on site.
                            </Typography>
                        )}
                        {site.regulatory_aerial_regulation && (
                            <Typography variant="body1" color="text.secondary">
                                Site is under a aerial regulation.
                            </Typography>
                        )}
                    </Grid>
                    <Grid sx={{ mt: 4 }} item xs={12} container spacing={2}>
                        <Grid sx={{height: 500}} item xs={12} lg={6}>
                            <SiteOrientations site={site} />
                        </Grid>
                        <Grid sx={{height: 500}} item xs={12} lg={6}>
                            <SiteMap site={site} />
                        </Grid>
                    </Grid>

                </Grid>
            </CardContent>
            <CardActions disableSpacing>

            </CardActions>
        </Card>
    );
}
