import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import SiteOverview from '../../sites/site-overview';
import ServicesDrawerLink from '../services-drawer-link';

export const TYPE = 'home';

export function useHomeService(site) {
    return useMemo(() => {
        const service = {
            type: TYPE,
            id: '',
            name: 'Home',
        }
        return service;
    }, [])
}

export function HomeServiceLink({ site, navigate }) {
    const service = useHomeService(site);
    return <ServicesDrawerLink service={service} navigate={navigate} fullName />
}

export default function HomeService({ site }) {
    return (
        <Box sx={{ height: '100%', width: '100%', p: 4 }}>
            <SiteOverview site={site} />
        </Box>
    );
}
