import { Model } from '@nozbe/watermelondb'
import { Q } from '@nozbe/watermelondb'
import { field, json, readonly, date, children, lazy } from '@nozbe/watermelondb/decorators'

export default class Beacon extends Model {
  static table = 'beacons'
  static associations = {
    sites: { type: 'has_many', foreignKey: 'wind_conditions_beacon' },
    weather_reports: { type: 'has_many', foreignKey: 'beacon' },
  }

  @readonly @field('_id') _id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt

  @readonly @field('provider') provider
  @readonly @field('provider_id') providerId
  @readonly @field('name') name
  @readonly @field('description_text') descriptionText
  @readonly @field('description_type') descriptionType

  @readonly @field('status') status

  @readonly @field('parameters_time_shift') parametersTimeShift
  @readonly @field('parameters_wind_direction_units') parametersWindDirectionUnits
  @readonly @field('parameters_wind_speed_units') parametersWindSpeedUnits

  @readonly @json('location_coordinates', (json) => json) locationCoordinates// JSON array
  @readonly @field('location_longitude') locationLongitude
  @readonly @field('location_latitude') locationLatitude
  @readonly @field('location_altitude') locationAltitude

  @children('sites') sites

  @lazy reports = this.collections.get('weatherreports')
    .query(
      Q.where('beacon', this.id),
      Q.sortBy('created_at', Q.desc)
    );

  @lazy lastReport = this.collections.get('weatherreports')
    .query(
      Q.where('beacon', this.id),
      Q.sortBy('created_at', Q.desc),
      Q.take(1)
    );
};
