import React from 'react';
import { Icon } from '@mui/material';

export default function SVGImage({ src, name = 'svgimage', ...props }) {
    return (
        <Icon {...props} sx={{
            textAlign: 'center',
            '.svg-image': {
                display: 'flex',
                height: 'inherit',
                width: 'inherit'
            }
        }}>
            <img className='svg-image' src={src} alt={name} />
        </Icon>
    )
}
