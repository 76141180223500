import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Box, Button, Typography } from '@mui/material';
import { useWatermelonReset, useWatermelonSynchronize } from '../../model/hooks';
import LayersConfigList from '../../components/map/layers-config';

const drawerWidth = 240;

export function SynchroniseDatabase({ ...props }) {
    const synchronize = useWatermelonSynchronize();

    return (
        <Button size='small' color='inherit' onClick={() => synchronize()}>
            Synchronize
        </Button>
    )
}

export function ResetDatabase({ ...props }) {
    const reset = useWatermelonReset();

    return (
        <Button size='small' color='secondary' onClick={() => reset()}>
            Reset
        </Button>
    )
}

export default function RightDrawer({ open = false, openDrawer, closeDrawer, takeoffUrlPrefix }) {

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                },
            }}
            variant="temporary"
            anchor="right"
            open={open}
            onClose={closeDrawer}
        >
            <LayersConfigList />
            <Divider />

            <Box sx={{
                flexGrow: 1,
            }}>
            </Box>
            <Divider />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center'
            }}>
                <ResetDatabase />
                <Typography color={'GrayText'}>{process.env.REACT_APP_VERSION}</Typography>
            </Box>
        </Drawer>
    );
}
