import React from 'react';
import L from 'leaflet';
import LandingImage from './Orange.svg';
import SVGImage from '../../../tools/components/svg-image';

export const LandingImageSVG = (props) => <SVGImage src={LandingImage} />


export const LandingIcon = L.Icon.extend({
    options: {
    iconUrl: LandingImage,
    iconRetinaUrl: LandingImage,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 15),
    className: 'leaflet-landing-marker',
}});
