import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, Switch } from '@mui/material';
import { TakeoffImageSVG } from '../sites/markers/takeoff-icon';
import { LandingImageSVG } from '../sites/markers/landing-icon';
import { WindImageSVG } from '../beacons/markers/windsock-icon';
import { useLayersConfiguration } from '../configuration/configuration-provider';

export default function LayersConfigList(props) {

    const [{ takeoffs, landings, beacons }, setLayersConfig] = useLayersConfiguration();

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            subheader={<ListSubheader>Map Layers</ListSubheader>}
        >
            <ListItem>
                <ListItemIcon>
                    <TakeoffImageSVG />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="Décollages" />
                <Switch
                    edge="end"
                    onChange={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setLayersConfig({ takeoffs: !takeoffs, landings, beacons });
                    }}
                    checked={takeoffs}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-takeoff',
                    }}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <LandingImageSVG />
                </ListItemIcon>
                <ListItemText id="switch-list-label-landings" primary="Atterissages" />
                <Switch
                    edge="end"
                    onChange={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setLayersConfig({ takeoffs, landings: !landings, beacons });
                    }}
                    checked={landings}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-landings',
                    }}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <WindImageSVG color='red' />
                </ListItemIcon>
                <ListItemText id="switch-list-label-beacons" primary="Balises Météo" />
                <Switch
                    edge="end"
                    onChange={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setLayersConfig({ takeoffs, landings, beacons: !beacons });
                    }}
                    checked={beacons}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-beacons',
                    }}
                />
            </ListItem>
        </List>
    )
}