import withObservables from '@nozbe/with-observables'
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider'
import { compose } from 'recompose'
import { Q } from '@nozbe/watermelondb'

export const observeBeaconForSite = compose(
    withDatabase,
    withObservables(['site'], ({ site }) => ({
        beacon: site.windConditionsBeacon,
    }))
)

export const observeBeaconById = compose(
    withDatabase,
    withObservables(['id'], ({ id, database }) => ({
        beacon: database.get('beacons').findAndObserve(id)
    }))
)

export const observeBeaconsListWithBounds = compose(
    withDatabase,
    withObservables(['bounds'], ({ bounds, database }) => ({
        beacons: database.get('beacons').query(
            Q.and(
                Q.where('location_latitude', Q.between(bounds._southWest.lat, bounds._northEast.lat)),
                Q.where('location_longitude', Q.between(bounds._southWest.lng, bounds._northEast.lng))
            )
        ),
    }))
)