import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useFetchReports } from '../weather-reports/hooks';
import WindDirection from '../weather-reports/wind-direction';
import WindDirections1h from '../weather-reports/wind-directions-1h';
import WindHistory1h from '../weather-reports/wind-history-1h';
import WindHistory24h from '../weather-reports/wind-history-24h';
import BeaconProviderAttribution from './beacon-attribution';
import BeaconStatusChip from './beacon-status-chip';
import { observeBeaconForSite } from './hoc';

export default function BeaconOverview({ beacon }) {

    useFetchReports(beacon);

    return (
        <Card>
            <CardHeader
                action={
                    <Fragment>
                        <BeaconStatusChip sx={{ mr: 2 }} status={beacon.status} />
                        <BeaconProviderAttribution provider={beacon.provider} id={beacon.providerId} />
                    </Fragment>
                }
                title={beacon.name}
                subheader={beacon.descriptionType && `Balise ${beacon.descriptionType}`}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <Typography variant="body1" color="text.secondary">
                            {beacon.descriptionText}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography sx={{ mt: 4 }} align='center' variant="body2" color="text.secondary">
                            Dernière direction du vent enregistrée par la balise.
                        </Typography>
                        <WindDirection beacon={beacon} />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Typography sx={{ mt: 4 }} align='center' variant="body2" color="text.secondary">
                            Vitesses du vent sur la dernière heure.
                        </Typography>
                        <WindHistory1h beacon={beacon} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography sx={{ mt: 4 }} align='center' variant="body2" color="text.secondary">
                            Répartition de la direction du vent sur la dernière heure.
                        </Typography>
                        <WindDirections1h beacon={beacon} />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Typography sx={{ mt: 4 }} align='center' variant="body2" color="text.secondary">
                            Vitesses du vent sur les 24 dernières heures.
                        </Typography>
                        <WindHistory24h beacon={beacon} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    );
}

export const BeaconOverviewForSite = observeBeaconForSite(BeaconOverview);
