import { useDatabase } from "@nozbe/watermelondb/hooks";
import { useCallback, useEffect } from "react";

export function useFecthReportsMethod() {
    return useCallback(async (beacon, since) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/weather-reports/${beacon._id}?since=${since}`);
        if (!response.ok) {
            throw new Error(await response.text())
        }
        const { data: reports } = await response.json();
        return reports;
    }, []);
}

export function useFetchReports(beacon) {
    const database = useDatabase();
    const fetchReports = useFecthReportsMethod();

    useEffect(() => {

        async function worker() {
            const last_report = (await beacon.lastReport.fetch())[0];
            let since = 0;
            if (last_report) {
                since = last_report.createdAt.getTime();
            }

            if (Date.now() - since < 5 * 60 * 1000) {
                return;
            }

            const reports = await fetchReports(beacon, since);
            await database.write(async () => {
                await Promise.all(
                    reports.map(
                        report => database.get('weatherreports').create(r => {
                            r._raw.id = report.id;
                            r._raw._id = report._id;
                            r._raw.created_at = report.created_at;
                            r._raw.measured_at = report.measured_at;
                            r.beacon.id = report.beacon;
                            r._raw.wind_direction = report.wind_direction;
                            r._raw.wind_direction_avg = report.wind_direction_avg;
                            r._raw.wind_speed_avg = report.wind_speed_avg;
                            r._raw.wind_speed_max = report.wind_speed_max;
                            r._raw.wind_speed_min = report.wind_speed_min;
                        }).catch(e => { })
                    )
                )
            });
        }

        worker().catch(e => console.error(e));

        // eslint-disable-next-line
    }, [beacon]);
}