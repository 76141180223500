import React from 'react';
import L from 'leaflet';
import SVGImage from '../../../tools/components/svg-image';
import TakeoffImage from './Blue.svg';

export const TakeoffImageSVG = (props) => <SVGImage src={TakeoffImage} />


export const TakeoffIcon = L.Icon.extend({
    options: {
        iconUrl: TakeoffImage,
        iconRetinaUrl: TakeoffImage,
        iconAnchor: null,
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(15, 15),
        className: 'leaflet-takeoff-marker',
    }
});
