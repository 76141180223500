import React, { useMemo } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { observeBeaconLastWeatherReport } from './hoc';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Filler,
    Tooltip,
    Legend
);

export const cardinalPoints = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSO', 'SO', 'OSO', 'O', 'ONO', 'NO', 'NNO'];

function useWindDirectionDataSet(report) {
    return useMemo(() => {
        const labels = new Array(360).fill(0).map((d, index) => index);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Min',
                    data: new Array(360).fill(0),
                    radius: 0,
                    backgroundColor: 'rgba(99, 99, 255, 0.3)',
                    borderColor: 'rgba(99, 99, 255, 0.8)',
                    borderWidth: 6,
                },
                {
                    label: 'Moyenne',
                    data: new Array(360).fill(0),
                    radius: 0,
                    backgroundColor: 'rgba(99, 255, 99, 0.5)',
                    borderColor: 'rgba(99, 255, 99, 0.8)',
                    borderWidth: 6,
                },
                {
                    label: 'Rafales',
                    data: new Array(360).fill(0),
                    radius: 0,
                    backgroundColor: 'rgba(255, 99, 99, 0.5)',
                    borderColor: 'rgba(255, 99, 99, 0.8)',
                    borderWidth: 6,
                },
            ],
        };

        if (report) {
            const { windDirectionAvg, windSpeedAvg, windSpeedMin, windSpeedMax } = report;

            if (windDirectionAvg !== null) {
                const direction = Math.floor(windDirectionAvg);
                data.datasets[0].data[direction] = windSpeedMin;
                data.datasets[1].data[direction] = windSpeedAvg;
                data.datasets[2].data[direction] = windSpeedMax;
            }
        }
        return data;
    }, [report]);
};

export function useWindDirectionOptions() {
    return useMemo(() => {
        const options = {
            plugins: {
                tooltip: {
                    enabled: false
                }
            },
            scales: {
                r: {
                    min: 0,
                    ticks: {
                        callback: function (val, index) {
                            return `${val} m/s`
                        },
                        color: (context) => {
                            if (context.tick.value > 6) return 'red';
                            else if (context.tick.value > 4) return 'orange';
                            return 'green';
                        }
                    },
                    pointLabels: {
                        callback: function (pointLabel) {
                            const index = Math.ceil(pointLabel / 22.5);
                            const predicate = (pointLabel % 45) === 0;
                            if (predicate) {
                                return `${cardinalPoints[index]} (${pointLabel}°)`
                            }
                            return null;
                        }
                    },
                    angleLines: {
                        color: null,
                    },
                    grid: {
                        color: (context) => {
                            if (context.tick.value > 6 - 1) return 'red';
                            else if (context.tick.value > 4 - 1) return 'orange';
                            return 'green';
                        }
                    }
                }
            }
        };
        return options;
    }, []);
};

function WindDirection({ reports, ...props }) {
    const data = useWindDirectionDataSet(reports[0]);
    const options = useWindDirectionOptions();

    return <Radar height={'100%'} data={data} options={options} />;
}

export default observeBeaconLastWeatherReport(WindDirection);
