import React, { useMemo } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartStreaming from 'chartjs-plugin-streaming';
import 'chartjs-adapter-luxon';
import { Line } from 'react-chartjs-2';
import { observeBeaconWeatherReports } from './hoc';
import { Box } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartStreaming,
);

function useWindHistory24hOptions(beacon, reports) {
    return useMemo(() => {
        return {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                tooltip: {
                    enabled: false
                },
            },
            scales: {
                x: {
                    type: 'time',
                    min: Date.now() - 24 * 60 * 60 * 1000,
                    max: Date.now(),
                    time: {
                        unit: 'minute'
                    }
                },
                y: {
                    ticks: {
                        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        callback: function (val, index) {
                            // Hide every 2nd tick label
                            return `${val} m/s`
                        },
                        color: (context) => {
                            if (context.tick.value > 6) return 'red';
                            else if (context.tick.value > 4) return 'orange';
                            return 'green';
                        }
                    },
                    grid: {
                        color: (context) => {
                            if (context.tick.value > 6) return 'red';
                            else if (context.tick.value > 4) return 'orange';
                            return 'green';
                        }
                    },
                }
            },
        }
    }, []);
}

function useWindHistory24hData(beacon, reports) {
    return useMemo(() => {
        return {
            datasets: [
                {
                    label: 'Min',
                    data: reports.map((report, index) => ({
                        x: report.createdAt,
                        y: report.windSpeedMin,
                    })),
                    tension: 0.5,
                    radius: 0,
                    fill: true,
                    borderColor: 'rgb(0, 0, 255)',
                    backgroundColor: 'rgb(0, 0, 255, 0.3)',
                },
                {
                    label: 'Moyennes',
                    data: reports.map((report, index) => ({
                        x: report.createdAt,
                        y: report.windSpeedAvg,
                    })),
                    tension: 0.5,
                    radius: 0,
                    fill: true,
                    borderColor: 'rgb(0, 255, 0)',
                    backgroundColor: 'rgba(0, 255, 0, 0.3)',
                },
                {
                    label: 'Rafales',
                    data: reports.map((report, index) => ({
                        x: report.createdAt,
                        y: report.windSpeedMax,
                    })),
                    tension: 0.5,
                    radius: 0,
                    fill: true,
                    borderColor: 'rgb(255, 0, 0)',
                    backgroundColor: 'rgba(255, 0, 0, 0.3)',
                },
            ],
        }
    }, [reports]);
}

function WindHistory24h({ beacon, reports }) {

    const options = useWindHistory24hOptions(beacon, reports);
    const data = useWindHistory24hData(beacon, reports);

    return (
        <Box sx={{ height: '500px' }}>
            <Line options={options} data={data} />;
        </Box>
    )
}

export default observeBeaconWeatherReports(WindHistory24h);
