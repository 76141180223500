import React from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import ServicesDrawer from './services-drawer';
import { Box } from '@mui/material';
import HomeService, { TYPE as HOME_TYPE } from './predefined/home';
import MeteoParapenteService, { TYPE as METEOPARAPENTE_TYPE } from './predefined/meteoparapente';
import WindyService, { TYPE as WINDY_TYPE } from './predefined/windy';
import { ID_3H, ID_DAY, ID_MAP, ID_SEEING, MeteoBlue3hService, MeteoBlueDayService, MeteoBlueMapService, MeteoBlueSeeingService, TYPE as METEOBLUE_TYPE } from './predefined/meteoblue';
import LiveService, { TYPE as LIVE_TYPE }  from './predefined/live';

const SERVICE_MAPPING = {
    [`${HOME_TYPE}/`]: HomeService,
    [`${LIVE_TYPE}/`]: LiveService,
    [`${METEOPARAPENTE_TYPE}/`]: MeteoParapenteService,
    [`${WINDY_TYPE}/`]: WindyService,
    [`${METEOBLUE_TYPE}/${ID_DAY}`]: MeteoBlueDayService,
    [`${METEOBLUE_TYPE}/${ID_3H}`]: MeteoBlue3hService,
    [`${METEOBLUE_TYPE}/${ID_MAP}`]: MeteoBlueMapService,
    [`${METEOBLUE_TYPE}/${ID_SEEING}`]: MeteoBlueSeeingService,
}

export function ServicePageUsingSite({ site, navigate, ...props }) {
    const { type, service_id = '' } = useParams();

    const key = `${type}/${service_id}`;
    const component = SERVICE_MAPPING[key] || null;

    return (
        <React.Fragment>
            <ServicesDrawer site={site} id={service_id} navigate={navigate} />
            <Box sx={{ flexGrow: 1 }}>
                {component && React.createElement(component, { ...props, site, type, id: service_id })}
            </Box>
        </React.Fragment>
    )
}

export default function ServicesPageWithDrawer(props) {
    const navigate = useNavigate();

    return (
        <Routes>
            <Route path='/w/:type/*' element={<ServicePageUsingSite {...props} navigate={navigate} />} />
            <Route path='/w/:type/:service_id/*' element={<ServicePageUsingSite {...props} navigate={navigate} />} />
            <Route path="/*" element={<Navigate to='w/home/' replace />} />
        </Routes>
    );
}