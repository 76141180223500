import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from './layout/header/app-bar';
import Main from './layout/page/main';
import RightDrawer from './layout/drawer/right-drawer';
import WatermelonProvider from './model';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import ScironThemeProvider from './theme/theme-provider';
import ConfigurationProvider from './components/configuration/configuration-provider';
import SitePage from './components/sites/site-page';
import BeaconPage from './components/beacons/beacon-page';
import { Button, Typography } from '@mui/material';
import MapWithRouter from './components/map/with-router-map';
import BuyMeACoffeeButton from './components/rewards/buy-me-a-coffee-button';

export function LocationsBasedView({ ...props }) {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  return (
    <ScironThemeProvider>
      <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
        <CssBaseline />
        <AppBar navigate={navigate} open={open} openDrawer={handleDrawerOpen} />
        <RightDrawer open={open} closeDrawer={handleDrawerClose} openDrawer={handleDrawerOpen} takeoffUrlPrefix='l/' />
        <Main>
          <Routes>
            <Route path='/map/*' exact element={<MapWithRouter changeLocation={navigate} />} />
            <Route path='/sites/:id/*' exact element={<SitePage />} />
            <Route path='/beacons/:id/*' exact element={<BeaconPage />} />
            <Route path="*" element={<Navigate to='map' replace />} />
          </Routes>
        </Main>
      </Box>
    </ScironThemeProvider>
  )
}

function ErrorFallback({ error, componentStack, resetError }) {

  useEffect(() => {
    setTimeout(() => resetError(), 8000);
    // eslint-disable-next-line
  }, []);

  return (
    <ScironThemeProvider>
      <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
        <CssBaseline />
        <AppBar noBack navigate={() => resetError()} />
        <Box sx={{
          height: "100%",
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}>
          <Box sx={{ height: 64, width: '100%' }} ></Box>
          <Box component="main" sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography>An error occured, we have been informed. The app will go back automatically.</Typography>
            <Button onClick={resetError}>Or go back now</Button>
          </Box>
        </Box>
      </Box>
    </ScironThemeProvider>

  );
}

function App() {

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => <ErrorFallback {...props} />}
    >
      <BuyMeACoffeeButton />
      <BrowserRouter>
        <WatermelonProvider>
          <ConfigurationProvider>
            <Routes>
              <Route path='/app/*' element={<LocationsBasedView />} />
              <Route path="*" element={<Navigate to='/app' replace />} />
            </Routes>
          </ConfigurationProvider>
        </WatermelonProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);