import withObservables from '@nozbe/with-observables'
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider'
import { Q } from '@nozbe/watermelondb'
import { compose } from 'recompose'

export const observeBeaconWeatherReports2 = withObservables(['beacon'], ({ beacon }) => ({
    reports: beacon.reports,
}))

export const observeBeaconWeatherReports = compose(
    withDatabase,
    withObservables(['beacon'], ({ beacon, database }) => ({
        reports: beacon.reports
    }))
)

export const observeBeaconWeatherReportsFor = (timeInSec) => compose(
    withDatabase,
    withObservables(['beacon'], ({ beacon, database }) => ({
        reports: database.get('weatherreports').query(
            Q.where('beacon', beacon.id),
            Q.sortBy('created_at', Q.desc),
            Q.take(timeInSec / (5 * 60))
        ),
    }))
)

export const observeBeaconLastWeatherReport = compose(
    withDatabase,
    withObservables(['beacon'], ({ beacon, database }) => ({
        reports: database.get('weatherreports').query(
            Q.where('beacon', beacon.id),
            Q.sortBy('created_at', Q.desc),
            Q.take(1)
        ),
    }))
)
