import React, { useCallback, useEffect } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ChevronLeft } from '@mui/icons-material';
import { Box, ButtonBase } from '@mui/material';

export const defaultTitle = 'Sciron';

export function usePageTitle(title) {
  useEffect(() => {
    document.title = title || defaultTitle;
    return () => {
      document.title = defaultTitle;
    }
    // eslint-disable-next-line
  }, []);
}

export function BackButton({ navigate = () => { } }) {

  const goBack = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    navigate(-1);
  }, [navigate]);

  return (
    <IconButton sx={{ mr: 2 }} color="inherit" onClick={goBack}>
      <ChevronLeft />
    </IconButton>
  )

}

export default function AppBar({ navigate = () => { }, open, noBack = false, openDrawer }) {

  return (
    <MuiAppBar position="fixed" enableColorOnDark open={open}>
      <Toolbar>
        {noBack || <BackButton navigate={navigate} />}
        <ButtonBase onClick={() => navigate('/')} >
          <Typography variant="h6" noWrap component="div">
            {defaultTitle}
          </Typography>
        </ButtonBase>
        <Box sx={{ flexGrow: 1 }} ></Box>
        {open !== undefined && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={openDrawer}
            sx={{ ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
    </MuiAppBar>
  );
}
