import withObservables from '@nozbe/with-observables'
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider'
import { compose } from 'recompose'

export const observeServicesFromSite = withObservables(['site'], ({ site }) => ({
    site,
    services: site.services,
}));

export const observeServiceById = compose(
    withDatabase,
    withObservables(['site'], ({ site }) => ({
        services: site.services,
    }))
)
