import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { BeaconOverviewForSite } from '../../beacons/beacon-overview';
import ServicesDrawerLink from '../services-drawer-link';

export const TYPE = 'live';

export function useLiveService(site) {
    return useMemo(() => {
        if (site.windConditionsBeacon.id !== "") {
            const service = {
                type: TYPE,
                id: '',
                name: 'Live',
            }
            return service;
        }
        return null;
    }, [site])
}

export function LiveServiceLink({ site, navigate }) {
    const service = useLiveService(site);
    if (service === null) {
        return null;
    }
    return <ServicesDrawerLink service={service} navigate={navigate} fullName />
}

export default function LiveService({ site }) {
    return (
        <Box sx={{ height: '100%', width: '100%', p: 4 }}>
            <BeaconOverviewForSite site={site} />
        </Box>
    );
}
