import React, { useMemo } from 'react';
import Service from '../service';
import ServicesDrawerLink from '../services-drawer-link';

export const TYPE = 'meteoblue';
export const ID_DAY = 'day';
export const ID_3H = 'three-hours';
export const ID_MAP = 'map';
export const ID_SEEING = 'seeing';

export function useMeteoBlueDayService(site) {
    return useMemo(() => {
        let { locationLongitude, locationLatitude, locationAltitude } = site;
        const longSufix = locationLongitude < 0 ? 'W' : 'E';
        const latSufix = locationLatitude < 0 ? 'S' : 'N';
        locationLatitude = Math.abs(locationLatitude);
        locationLongitude = Math.abs(locationLongitude);

        const service = {
            type: TYPE,
            id: ID_DAY,
            icon: 'https://www.meteoblue.com/favicon.ico',
            url: `https://www.meteoblue.com/fr/meteo/widget/daily/${locationLatitude}${latSufix}${locationLongitude}${longSufix}${locationAltitude}?geoloc=fixed&days=7&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&precipunit=MILLIMETER&coloured=coloured&pictoicon=0&pictoicon=1&maxtemperature=0&maxtemperature=1&mintemperature=0&mintemperature=1&windspeed=0&windspeed=1&windgust=0&windgust=1&winddirection=0&winddirection=1&uv=0&humidity=0&precipitation=0&precipitation=1&precipitationprobability=0&precipitationprobability=1&spot=0&spot=1&pressure=0&layout=light`,
            name: 'Meteo Blue',
        }
        return service;
    }, [site])
}

export function useMeteoBlue3hService(site) {
    return useMemo(() => {
        let { locationLongitude, locationLatitude, locationAltitude } = site;
        const longSufix = locationLongitude < 0 ? 'W' : 'E';
        const latSufix = locationLatitude < 0 ? 'S' : 'N';
        locationLatitude = Math.abs(locationLatitude);
        locationLongitude = Math.abs(locationLongitude);

        const service = {
            type: TYPE,
            id: ID_3H,
            icon: 'https://www.meteoblue.com/favicon.ico',
            url: `https://www.meteoblue.com/fr/meteo/widget/three/${locationLatitude}${latSufix}${locationLongitude}${longSufix}${locationAltitude}?geoloc=fixed&nocurrent=0&noforecast=0&days=4&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&layout=image`,
            name: 'Meteo Blue',
        }
        return service;
    }, [site])
}

export function useMeteoBlueMapService(site) {
    return useMemo(() => {
        let { locationLongitude, locationLatitude, locationAltitude } = site;
        const longSufix = locationLongitude < 0 ? 'W' : 'E';
        const latSufix = locationLatitude < 0 ? 'S' : 'N';
        locationLatitude = Math.abs(locationLatitude);
        locationLongitude = Math.abs(locationLongitude);

        const service = {
            type: TYPE,
            id: ID_MAP,
            icon: 'https://www.meteoblue.com/favicon.ico',
            url: `https://www.meteoblue.com/fr/meteo/cartes/widget/${locationLatitude}${latSufix}${locationLongitude}${longSufix}${locationAltitude}?windAnimation=0&windAnimation=1&gust=0&gust=1&satellite=0&cloudsAndPrecipitation=0&cloudsAndPrecipitation=1&temperature=0&sunshine=0&extremeForecastIndex=0&geoloc=fixed&tempunit=C&windunit=km%252Fh&lengthunit=metric&zoom=9&autowidth=auto`,
            name: 'Meteo Blue',
        }
        return service;
    }, [site])
}

export function useMeteoBlueSeeingService(site) {
    return useMemo(() => {
        let { locationLongitude, locationLatitude, locationAltitude } = site;
        const longSufix = locationLongitude < 0 ? 'W' : 'E';
        const latSufix = locationLatitude < 0 ? 'S' : 'N';
        locationLatitude = Math.abs(locationLatitude);
        locationLongitude = Math.abs(locationLongitude);

        const service = {
            type: TYPE,
            id: ID_SEEING,
            icon: 'https://www.meteoblue.com/favicon.ico',
            url: `https://www.meteoblue.com/fr/meteo/widget/seeing/${locationLatitude}${latSufix}${locationLongitude}${longSufix}${locationAltitude}?geoloc=fixed&noground=0`,
            name: 'Meteo Blue',
        }
        return service;
    }, [site])
}


export function MeteoBlueService3hLink({ site, navigate }) {
    const service = useMeteoBlue3hService(site);
    return <ServicesDrawerLink service={service} navigate={navigate} />
}

export function MeteoBlue3hService({ site }) {
    const service = useMeteoBlue3hService(site);
    return (
        <Service service={service} />
    );
}

export function MeteoBlueServiceDayLink({ site, navigate }) {
    const service = useMeteoBlueDayService(site);
    return <ServicesDrawerLink service={service} navigate={navigate} />
}

export function MeteoBlueDayService({ site }) {
    const service = useMeteoBlueDayService(site);
    return (
        <Service service={service} />
    );
}

export function MeteoBlueServiceMapLink({ site, navigate }) {
    const service = useMeteoBlueMapService(site);
    return <ServicesDrawerLink service={service} navigate={navigate} />
}

export function MeteoBlueMapService({ site }) {
    const service = useMeteoBlueMapService(site);
    return (
        <Service service={service} />
    );
}

export function MeteoBlueServiceSeeingLink({ site, navigate }) {
    const service = useMeteoBlueSeeingService(site);
    return <ServicesDrawerLink service={service} navigate={navigate} />
}

export function MeteoBlueSeeingService({ site }) {
    const service = useMeteoBlueSeeingService(site);
    return (
        <Service service={service} />
    );
}