import { useMemo } from 'react'
import { createTheme } from '@mui/material/styles'

export const useDefaultScironTheme = (mode) => {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: '#302b67',
            main: '#01013c',
            dark: '#000019',
            contrastText: '#ffffff'
          },
          secondary: {
            light: '#ff844c',
            main: '#f4511e',
            dark: '#b91400',
            contrastText: '#000000'
          },
          mode
        }
      }),
    [mode]
  )
  return theme
}
