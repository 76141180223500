import { useCallback } from 'react';
import { useDatabase } from '@nozbe/watermelondb/hooks';
import { synchronize } from '@nozbe/watermelondb/sync'
import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync'

export function useUnsyncedChanges() {
    const database = useDatabase();
    return useCallback(() => hasUnsyncedChanges({
        database
    }), [database]);
}

export const useWatermelonReset = () => {
    const database = useDatabase();
    return useCallback(async () => await database.write(async () => await database.unsafeResetDatabase()), [database]);
}

export const useWatermelonSynchronize = () => {
    const database = useDatabase();
    return useCallback(async ({ changes = undefined } = {}) => await synchronize({
        database,
        pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
            const urlParams = `last_pulled_at=${lastPulledAt}&schema_version=${schemaVersion}&migration=${encodeURIComponent(JSON.stringify(migration))}`
            const response = await fetch(`${process.env.REACT_APP_API_URL}/watermelon?${urlParams}`)
            if (!response.ok) {
                throw new Error(await response.text())
            }

            const json = await response.json();
            const { changes, timestamp } = json;
            return { changes, timestamp }
        },
        pushChanges: async ({ changes, lastPulledAt }) => {
            throw new Error('not implemented');
        },
        migrationsEnabledAtVersion: 1,
    }), [database]);
}
