import { Model } from '@nozbe/watermelondb'
import { field, date, readonly, json, children, immutableRelation } from '@nozbe/watermelondb/decorators'

export default class Site extends Model {
  static table = 'sites'
  static associations = {
    services: { type: 'has_many', foreignKey: 'site_id' },
    beacons: { type: 'belongs_to', key: 'wind_conditions_beacon' },
  }

  @readonly @field('_id') _id
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt

  @field('type') type
  @field('provider') provider
  @field('provider_id') providerId

  @field('name') name
  @field('short_name') shortName
  @field('description_text') descriptionText
  @field('description_wind') descriptionWind
  @field('description_hazards') descriptionHazards
  @field('description_restrictions') descriptionRestrictions

  @field('location_type') locationType
  @json('location_coordinates', (json) => json) locationCoordinates
  @field('location_longitude') locationLongitude
  @field('location_latitude') locationLatitude
  @field('location_altitude') locationAltitude
  @field('location_city') locationCity
  @field('location_post_code') locationPostCode
  @field('location_help') locationHelp

  @json('wind_conditions_orientation', (json) => json) windConditionsOrientation
  @json('wind_conditions_best', (json) => json) windConditionsBest
  @json('wind_conditions_unfriendly', (json) => json) windConditionsUnfriendly
  @immutableRelation('beacons', 'wind_conditions_beacon') windConditionsBeacon

  @field('regulatory_information_panel') regulatoryInformationPanel
  @field('regulatory_aerial_regulation') regulatoryAerialRegulation

  @readonly @date('last_modified') lastModified

  @children('services') services
};
