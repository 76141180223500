import withObservables from '@nozbe/with-observables'
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider'
import { Q } from '@nozbe/watermelondb'
import { compose } from 'recompose'

export const observeSiteById = compose(
    withDatabase,
    withObservables(['id'], ({ id, database }) => ({
        site: database.get('sites').findAndObserve(id)
    }))
)

export const observeTakeoffsListWithBounds = compose(
    withDatabase,
    withObservables(['bounds'], ({ bounds, database }) => ({
        takeoffs: database.get('sites').query(
            Q.where('type', 'takeoff'),
            Q.and(
                Q.where('location_latitude', Q.between(bounds._southWest.lat, bounds._northEast.lat)),
                Q.where('location_longitude', Q.between(bounds._southWest.lng, bounds._northEast.lng))
            )
        ),
    }))
)

export const observeLandingsListWithBounds = compose(
    withDatabase,
    withObservables(['bounds'], ({ bounds, database }) => ({
        landings: database.get('sites').query(
            Q.where('type', 'landing'),
            Q.and(
                Q.where('location_latitude', Q.between(bounds._southWest.lat, bounds._northEast.lat)),
                Q.where('location_longitude', Q.between(bounds._southWest.lng, bounds._northEast.lng))
            )
        ),
    }))
)
