import React, { useCallback, useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useDatabase } from '@nozbe/watermelondb/hooks';
import SitesMapLayer from '../sites/sites-map-layer';
import BeaconsMapLayer from '../beacons/beacons-map-layer';

const defaultLatitude = 46.1390489;
const defaultLongitude = 1;
const defaultZoom = 6;

const useSetLastPosition = () => {
    const database = useDatabase();
    return useCallback(async ({ latitude, longitude }, zoom) => {
        await database.localStorage.set("last_position", JSON.stringify({ latitude, longitude, zoom }));
    }, [database]);
}

const useLastPosition = () => {
    const [latLong, setLatLong] = useState({ latitude: defaultLatitude, longitude: defaultLongitude });
    const [zoom, setZoom] = useState(defaultZoom);
    const [loading, setLoading] = useState(true);
    const database = useDatabase();

    useEffect(() => {

        const saver = async () => {
            const position = await database.localStorage.get("last_position");
            if (position) {
                const { latitude, longitude, zoom } = JSON.parse(position);
                setLatLong({ latitude, longitude });
                setZoom(zoom);
            }
        }

        saver()
            .catch(e => console.error(e))
            .finally(() => setLoading(false));

        // eslint-disable-next-line
    }, []);

    return [loading, latLong, zoom];
}

export function MapInternal({ latitude = defaultLatitude, longitude = defaultLongitude, zoom = defaultZoom, children }) {

    return (
        <MapContainer
            style={{
                width: '100%',
                height: "100%",
            }}
            center={[latitude, longitude]} zoom={zoom} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {children}
        </MapContainer>
    );
}

export function MapRouterControl({ changeCenterAndZoom }) {

    const map = useMap();

    const onMove = useCallback(() => {
        const { lat, lng: lon } = map.getCenter();
        const zoom = map.getZoom();
        changeCenterAndZoom(lat, lon, zoom);
    }, [map, changeCenterAndZoom])

    useEffect(() => {
        map.on('moveend', onMove)
        return () => {
            map.off('moveend', onMove)
        }
    }, [map, onMove])

    return null;
}

export function MapLastPositionController() {

    const map = useMap();
    const setLastPosition = useSetLastPosition();

    const onMove = useCallback(() => {
        const { lat: latitude, lng: longitude } = map.getCenter();
        const zoom = map.getZoom();
        setLastPosition({ latitude, longitude }, zoom);
    }, [map, setLastPosition])

    useEffect(() => {
        map.on('moveend', onMove)
        return () => {
            map.off('moveend', onMove)
        }
    }, [map, onMove])

    return null;
}

export function MapWithRouterInternal({ navigate, ...props }) {
    const { latitude, longitude, zoom } = useParams();

    const changeCenterAndZoom = useCallback((lat, lon, zoom) => {
        navigate(`${lat}/${lon}/${zoom}`, { replace: true });
    }, [navigate]);

    return (
        <MapInternal {...props} latitude={latitude} longitude={longitude} zoom={zoom}>
            <MapRouterControl changeCenterAndZoom={changeCenterAndZoom} />
            <MapLastPositionController />
            <SitesMapLayer />
            <BeaconsMapLayer />
        </MapInternal>
    )
}

export function WaitLastMapPosition() {

    const [loading, position, zoom] = useLastPosition();

    if (loading) {
        return null;
    }

    return (
        <Navigate to={`${position.latitude}/${position.longitude}/${zoom}`} replace />
    )
}

export default function MapWithRouter(props) {

    const navigate = useNavigate();

    return (
        <Routes>
            <Route path='/:latitude/:longitude/:zoom' exact element={<MapWithRouterInternal {...props} navigate={navigate} />} />
            <Route path="*" element={<WaitLastMapPosition />} />
        </Routes>
    )
}
