import { useDatabase } from '@nozbe/watermelondb/hooks'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

const ConfigurationContext = createContext({
    layers: {
        takeoffs: true,
        landings: false,
        beacons: false,
    },
    setLayers: async () => {
        console.error('early call to setLayers()')
    },
    priviledge: null,
    togglePriviledge: () => {
        console.error('early call to togglePriviledge()')
    }
})

export const useConfigurationPriviledge = () => {
    const { priviledge, togglePriviledge } = useContext(ConfigurationContext);
    return [priviledge, togglePriviledge];
}

export const useLayersConfiguration = () => {
    const { layers, setLayers } = useContext(ConfigurationContext);
    return [layers, setLayers];
}

export default function ConfigurationProvider({ children = null }) {

    const database = useDatabase();

    const [layers, _setLayers] = useState({ takeoffs: true, landings: false, beacons: false });
    const setLayers = useCallback(async ({ takeoffs = false, landings = false, beacons = false }) => {
        await database.localStorage.set("layers_config", JSON.stringify({ takeoffs, landings, beacons }));
        _setLayers({ takeoffs, landings, beacons });
    }, [database, _setLayers]);

    const [priviledge, setPriviledge] = useState(false);
    const togglePriviledge = useCallback(() => {
        setPriviledge(m => !m)
    }, [setPriviledge])

    const context = useMemo(
        () => ({
            layers,
            setLayers,
            priviledge,
            togglePriviledge,
        }),
        [priviledge, layers, setLayers, togglePriviledge]
    )

    useEffect(() => {
        const getter = async () => {
            const conf = await database.localStorage.get("layers_config");
            if (conf) {
                const config = JSON.parse(conf);
                _setLayers(config);
            }
        }
        getter()
            .catch(e => console.error(e))

        // eslint-disable-next-line
    }, []);

    return (
        <ConfigurationContext.Provider value={context}>
            {children}
        </ConfigurationContext.Provider>
    )
}
