import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import BeaconOverview from './beacon-overview';
import { observeBeaconById } from './hoc';

function BeaconPageInternal({ beacon, ...props }) {

    return (
        <Box sx={{ height: '100%', width: '100%', p: 4 }}>
            <BeaconOverview beacon={beacon} />
        </Box>
    );
}

const ObservedBeaconPage = observeBeaconById(BeaconPageInternal);

export default function BeaconPage({ ...props }) {
    const { id } = useParams();
    return (
        <ObservedBeaconPage id={id} />
    );
}