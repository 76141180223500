import React, { Fragment, useCallback } from 'react';
import { LayerGroup, Marker, Tooltip, useMap } from 'react-leaflet'
import { observeLandingsListWithBounds, observeTakeoffsListWithBounds } from './hoc';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { LandingIcon } from './markers/landing-icon';
import { TakeoffIcon } from './markers/takeoff-icon';
import { useLayersConfiguration } from '../configuration/configuration-provider';

export function useNavigateToSite() {
    const navigate = useNavigate();
    return useCallback((id) => {
        navigate(`/app/sites/${id}`);
    }, [navigate]);
}

export function LandingsMapLayerInternal({ landings }) {

    const navigateToSite = useNavigateToSite();

    return (
        <LayerGroup>
            {_.map(landings, site => (
                <Marker key={site.id} position={site.locationCoordinates}
                    eventHandlers={{
                        click: () => navigateToSite(site.id),
                    }}
                    icon={new LandingIcon()}
                >
                    {site.name && <Tooltip>{site.name}</Tooltip>}
                </Marker>
            ))}
        </LayerGroup>
    );
}

const ObservedLandingsMapLayer = observeLandingsListWithBounds(LandingsMapLayerInternal);


export function TakeOffsMapLayerInternal({ takeoffs }) {

    const navigateToSite = useNavigateToSite();

    return (
        <LayerGroup>
            {_.map(takeoffs, site => (
                <Marker key={site.id} position={site.locationCoordinates}
                    eventHandlers={{
                        click: () => navigateToSite(site.id),
                    }}
                    icon={new TakeoffIcon()}
                >
                    {site.name && <Tooltip>{site.name}</Tooltip>}
                </Marker>
            ))}
        </LayerGroup>
    );
}

const ObservedTakeOffsMapLayer = observeTakeoffsListWithBounds(TakeOffsMapLayerInternal);

export default function SitesMapLayer(props) {

    const map = useMap();
    const bounds = map.getBounds();
    const [{ takeoffs, landings }] = useLayersConfiguration();

    return (
        <Fragment>
            {takeoffs && <ObservedTakeOffsMapLayer bounds={bounds} />}
            {landings && <ObservedLandingsMapLayer bounds={bounds} />}
        </Fragment>
    )
}
