import React, { useMemo } from 'react'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider'
import { Database } from '@nozbe/watermelondb'
import schema from './schema'
import migrations from './migrations'
import Service from './Services'
import Beacon from './Beacons'
import Site from './Sites'
import WetaherReports from './WeatherReports'

function WatermelonProvider({ name = 'weather-paragliding', children = null }) {

    const adapter = useMemo(() => {
        return new LokiJSAdapter({
            dbName: name,
            schema,
            // (You might want to comment out migrations for development purposes -- see Migrations documentation)
            migrations,
            useWebWorker: false,
            useIncrementalIndexedDB: true,
            // dbName: 'myapp', // optional db name

            // --- Optional, but recommended event handlers:

            onQuotaExceededError: (error) => {
                // Browser ran out of disk space -- offer the user to reload the app or log out
            },
            onSetUpError: (error) => {
                console.error('failed to load database', error);
                // Database failed to load -- offer the user to reload the app or log out
            },
            extraIncrementalIDBOptions: {
                onDidOverwrite: () => {
                    // Called when this adapter is forced to overwrite contents of IndexedDB.
                    // This happens if there's another open tab of the same app that's making changes.
                    // Try to synchronize the app now, and if user is offline, alert them that if they close this
                    // tab, some data may be lost
                },
                onversionchange: () => {
                    // database was deleted in another browser tab (user logged out), so we must make sure we delete
                    // it in this tab as well - usually best to just refresh the page
                    //if (checkIfUserIsLoggedIn()) {
                    //    window.location.reload()
                    //}
                },
            }
        });
    }, [name]);

    const database = useMemo(() => {
        return new Database({
            adapter,
            modelClasses: [
                Beacon,
                WetaherReports,
                Service,
                Site,
            ],
        });
    }, [adapter]);

    return (
        <DatabaseProvider database={database}>
            {children}
        </DatabaseProvider>
    )
}

export default WatermelonProvider;
