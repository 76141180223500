import React, { createContext, useContext, useMemo, useState } from 'react'
import { ThemeProvider as Themed } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { useDefaultScironTheme } from './themes'

const ColorModeContext = createContext({
  mode: null,
  toggleColorMode: () => {
    console.error('early call to toggleColorMode()')
  }
})

export const useColorMode = () => useContext(ColorModeContext)

export default function ScironThemeProvider({ children = null }) {
  const [mode, setMode] = useState('light')

  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        setMode(mode === 'light' ? 'dark' : 'light')
      }
    }),
    [mode]
  )

  const theme = useDefaultScironTheme(mode);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <Themed theme={theme}>
        <CssBaseline />
        {children}
      </Themed>
    </ColorModeContext.Provider>
  )
}
