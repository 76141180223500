import * as React from 'react';
import { useParams } from 'react-router-dom';
import { usePageTitle } from '../../layout/header/app-bar';
import ServicesPageWithDrawer from '../services/services-with-drawer';
import { observeSiteById } from './hoc';

export function SitePageInternal({ site }) {
    
    usePageTitle(site.name);

    return (
        <ServicesPageWithDrawer site={site} />
    )
}

const ObservedSitePage = observeSiteById(SitePageInternal);

export default function SitePage({ ...props }) {
    const { id } = useParams();

    return (
        <ObservedSitePage id={id} />
    );
}