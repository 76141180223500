import React, { Fragment, useCallback } from 'react';
import { LayerGroup, Marker, Tooltip, useMap } from 'react-leaflet'
import { observeBeaconsListWithBounds } from './hoc';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { WindSockIcon } from './markers/windsock-icon';
import { useLayersConfiguration } from '../configuration/configuration-provider';

export function useNavigateToBeacon() {
    const navigate = useNavigate();
    return useCallback((id) => {
        navigate(`/app/beacons/${id}`);
    }, [navigate]);
}

export function BeaconsMapLayerInternal({ beacons }) {

    const navigateToBeacon = useNavigateToBeacon();

    return (
        <LayerGroup>
            {_.map(beacons, beacon => (
                <Marker key={beacon.id} position={beacon.locationCoordinates}
                    eventHandlers={{
                        click: () => navigateToBeacon(beacon.id),
                    }}
                    icon={new WindSockIcon()}
                >
                    {beacon.name && <Tooltip>Balise {beacon.name}</Tooltip>}
                </Marker>
            ))}
        </LayerGroup>
    );
}

const ObservedBeaconsMapLayer = observeBeaconsListWithBounds(BeaconsMapLayerInternal);

export default function BeaconsMapLayer(props) {

    const map = useMap();
    const bounds = map.getBounds();
    const [{ beacons }] = useLayersConfiguration();

    return (
        <Fragment>
            {beacons && <ObservedBeaconsMapLayer bounds={bounds} />}
        </Fragment>
    )
}
