import { Model } from '@nozbe/watermelondb'
import { field, date, relation } from '@nozbe/watermelondb/decorators'

export default class WetaherReports extends Model {
  static table = 'weatherreports'
  static associations = {
    beacons: { type: 'belongs_to', key: 'beacon' },
  }
  @field('_id') _id
  @date('created_at') createdAt
  @date('measured_at') measuredAt
  @relation('beacons', 'beacon') beacon
  @field('wind_direction') windDirection
  @field('wind_direction_avg') windDirectionAvg
  @field('wind_speed_avg') windSpeedAvg
  @field('wind_speed_max') windSpeedMax
  @field('wind_speed_min') windSpeedMin
};
