import React from 'react';
import { Chip } from '@mui/material';

export default function BeaconProviderAttribution({ provider, id }) {
    let attribution = '';
    let url = '';

    switch (provider) {
        case 'ffvl':
            attribution = 'Data kindly provided by FFVL';
            url = `http://www.balisemeteo.com/balise.php?idBalise=${id}`;
            break;
        case 'owm':
            attribution = 'Data kindly provided by Open Wind Map';
            url = `https://openwindmap.org/PP${id}`;
            break;
        default:
            break;
    }

    const handleClick = url != null ? () => window.open(url, '_blank') : null;

    if (provider == null) {
        return null;
    }

    return <Chip color='primary' label={attribution} onClick={handleClick} />

}
