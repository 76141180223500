import React, { useMemo } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Filler,
    Tooltip,
    Legend
);

function toDegrees(degre) {
    const rest = degre % 360;
    if (rest < 0) {
        return 360 - rest;
    } else {
        return rest;
    }
}

function useWindOrientationsDataSet(site) {
    return useMemo(() => {
        const labels = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSO', 'SO', 'OSO', 'O', 'ONO', 'NO', 'NNO'];
        const data = {
            labels,
            datasets: [
                {
                    label: 'Site Orientation',
                    data: [],
                    backgroundColor: 'rgba(99, 99, 255, 0.3)',
                    borderColor: 'rgba(99, 99, 255, 0.8)',
                    borderWidth: 2,
                    radius: 0,
                },
                {
                    label: 'Best Wind Directions',
                    data: [],
                    backgroundColor: 'rgba(99, 255, 99, 0.5)',
                    borderColor: 'rgba(99, 255, 99, 0.8)',
                    borderWidth: 0,
                    radius: 0,
                },
                {
                    label: 'Be careful',
                    data: [],
                    backgroundColor: 'rgba(255, 99, 99, 0.5)',
                    borderColor: 'rgba(255, 99, 99, 0.8)',
                    borderWidth: 0,
                    radius: 0,
                },
            ],
        };

        const { windConditionsOrientation, windConditionsBest, windConditionsUnfriendly } = site;

        function runOnCardinalsPoints(degrees, degre, data) {
            if (degrees.indexOf(toDegrees(degre)) !== -1) {
                data.push(1);
            } else if (degrees.indexOf(toDegrees(degre - 22.5)) !== -1) {
                data.push(1);
            } else if (degrees.indexOf(toDegrees(degre + 22.5)) !== -1) {
                data.push(1);
            } else {
                data.push(0);
            }
        }
        labels.forEach((label, index) => {
            const degre = (index * 22.5);
            runOnCardinalsPoints(windConditionsOrientation, degre, data.datasets[0].data);
            runOnCardinalsPoints(windConditionsBest, degre, data.datasets[1].data);
            runOnCardinalsPoints(windConditionsUnfriendly, degre, data.datasets[2].data);
        });

        return data;
    }, [site]);
};


export function useWindOrientationsOptions() {
    return useMemo(() => {
        const options = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: false
                }
            },
            scales: {
                r: {
                    min: 0,
                    ticks: {
                        callback: function (val, index) {
                            return null;
                        },
                    },
                }
            }
        };
        return options;
    }, []);
};

export default function SiteOrientations({ site, ...props }) {
    const data = useWindOrientationsDataSet(site);
    const options = useWindOrientationsOptions();
    return <Radar data={data} options={options} />;
}