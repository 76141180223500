import React from 'react';
import { Drawer, List, ListItem } from '@mui/material';
import { observeServicesFromSite } from './hoc';
import { MeteoParapenteServiceLink } from './predefined/meteoparapente';
import { WindyServiceLink } from './predefined/windy';
import ServicesDrawerLink from './services-drawer-link';
import { MeteoBlueService3hLink, MeteoBlueServiceDayLink, MeteoBlueServiceMapLink, MeteoBlueServiceSeeingLink } from './predefined/meteoblue';
import { HomeServiceLink } from './predefined/home';
import { LiveServiceLink } from './predefined/live';

function ServicesDrawer({ site, services, id, navigate, ...props }) {
    return (
        <Drawer variant="persistent" open
            PaperProps={{ sx: { position: 'relative', zIndex: 10, width: '64px' } }}
        >
            <List>
                <HomeServiceLink site={site} navigate={navigate} />
                <LiveServiceLink site={site} navigate={navigate} />
                <MeteoParapenteServiceLink site={site} navigate={navigate} />
                <WindyServiceLink site={site} navigate={navigate} />
                <MeteoBlueServiceDayLink site={site} navigate={navigate} />
                <MeteoBlueService3hLink site={site} navigate={navigate} />
                <MeteoBlueServiceMapLink site={site} navigate={navigate} />
                <MeteoBlueServiceSeeingLink site={site} navigate={navigate} />
                {services.map((service) => {
                    return (
                        <ServicesDrawerLink key={`${service.type}/${service.id}`} service={service} />
                    )
                })}
                <ListItem disablePadding>
                    {/*<AddServiceButton site={site} />*/}
                </ListItem>
            </List>
        </Drawer>
    );
}

export default observeServicesFromSite(ServicesDrawer);
