import React, { useMemo } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { observeBeaconWeatherReportsFor } from './hoc';
import { useWindDirectionOptions } from './wind-direction';
import _ from 'lodash';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Filler,
    Tooltip,
    Legend
);

function useWindDirection1hDataSet(reports) {
    return useMemo(() => {
        const labels = new Array(360).fill(0).map((d, index) => index);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Min',
                    data: new Array(360).fill(0),
                    radius: 0,
                    backgroundColor: 'rgba(99, 99, 255, 0.3)',
                    borderColor: 'rgba(99, 99, 255, 0.8)',
                    borderWidth: 4,
                },
                {
                    label: 'Moyenne',
                    data: new Array(360).fill(0),
                    radius: 0,
                    backgroundColor: 'rgba(99, 255, 99, 0.5)',
                    borderColor: 'rgba(99, 255, 99, 0.8)',
                    borderWidth: 4,
                },
                {
                    label: 'Rafales',
                    data: new Array(360).fill(0),
                    radius: 0,
                    backgroundColor: 'rgba(255, 99, 99, 0.5)',
                    borderColor: 'rgba(255, 99, 99, 0.8)',
                    borderWidth: 4,
                },
            ],
        };

        const averages = {};
        const averagesMin = {};
        const averagesMax = {};

        reports.forEach(report => {
            const { windDirectionAvg, windSpeedAvg, windSpeedMin, windSpeedMax } = report;

            if (windDirectionAvg !== null) {
                const direction = Math.floor(windDirectionAvg);

                averagesMin[direction] = averagesMin[direction] || [];
                averagesMax[direction] = averagesMax[direction] || [];
                averages[direction] = averages[direction] || [];

                averagesMin[direction].push(windSpeedMin);
                averagesMax[direction].push(windSpeedMax);
                averages[direction].push(windSpeedAvg);
            }
        });

        _.each(averagesMin, (speeds, key) => {
            data.datasets[0].data[key] = _.mean(speeds);
        });

        _.each(averagesMax, (speeds, key) => {
            data.datasets[2].data[key] = _.mean(speeds);
        });

        _.each(averages, (speeds, key) => {
            data.datasets[1].data[key] = _.mean(speeds);
        });

        return data;
    }, [reports]);
};

function WindDirection1h({ reports, ...props }) {
    const data = useWindDirection1hDataSet(reports);
    const options = useWindDirectionOptions(reports);

    return <Radar data={data} options={options} />;
}

export default observeBeaconWeatherReportsFor(3600 * 12)(WindDirection1h);
