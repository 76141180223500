import React from 'react';
import L from 'leaflet'; 
import SVGImage from '../../../tools/components/svg-image';
import WindImage from './Green.svg';

export const WindImageSVG = (props) => <SVGImage src={WindImage} />

export const WindSockIcon = L.Icon.extend({
    options: {
        iconUrl: WindImage,
        iconRetinaUrl: WindImage,
        iconAnchor: null,
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(15, 15),
        className: 'leaflet-windsock-marker',
    }
});
