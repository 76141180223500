import React, { useMemo } from 'react';
import Service from '../service';
import ServicesDrawerLink from '../services-drawer-link';

export const TYPE = 'windy';

export function useWindyService(site) {
    return useMemo(() => {
        const { locationLongitude, locationLatitude } = site;
        const service = {
            type: TYPE,
            id: '',
            icon: 'https://www.windy.com/favicon.ico',
            url: `https://embed.windy.com/embed2.html?lat=${locationLatitude}&lon=${locationLongitude}&detailLat=${locationLatitude}&detailLon=${locationLongitude}&width=650&height=450&zoom=10&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=km%2Fh&metricTemp=default&radarRange=-1`,
            name: 'Windy',
        }
        return service;
    }, [site])
}

export function WindyServiceLink({ site, navigate }) {
    const service = useWindyService(site);
    return <ServicesDrawerLink service={service} navigate={navigate} />
}

export default function WindyService({ site }) {
    const service = useWindyService(site);
    return (
        <Service service={service} />
    );
}
