import * as React from 'react';
import { Badge, Button, IconButton, ListItem, Tooltip, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfigurationPriviledge } from '../configuration/configuration-provider';
import { useDeleteService } from '../../model/Services';

function PriviledgedServiceButton({ service, children }) {

    const [priviledge] = useConfigurationPriviledge();
    const deleteService = useDeleteService();

    if (priviledge) {
        return (
            <Badge badgeContent={(
                <Tooltip title='Delete service'>
                    <IconButton color='secondary' onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        deleteService(service);
                    }}>
                        <DeleteIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            )}>
                {children}
            </Badge>
        )
    } else {
        return children;
    }

}

export default function ServicesDrawerLink({ service, navigate, fullName, selected }) {

    const theme = useTheme();

    const handleClick = (ev, service) => {
        ev.preventDefault();
        ev.stopPropagation();
        navigate(`w/${service.type}/${service.id}`);
    }

    let button = null;


    if (service.icon) {
        button = (
            <Button onClick={(ev) => handleClick(ev, service)}
                sx={{ minWidth: 'unset' }}
                size='medium'>
                <img height='32px' src={service.icon} alt={service.name} />
            </Button>
        );
    } else {
        button = (
            <Button
                onClick={(ev) => handleClick(ev, service)}
                sx={{ minWidth: 'unset' }}
                color='primary'
                variant={fullName ? 'text' : 'outlined'}
                size='medium'>
                {fullName ? service.name : service.name.toUpperCase().slice(0, 1)}
            </Button>
        );
    }

    return (
        <ListItem key={service.id} disablePadding sx={{
            borderLeft: selected ? `${theme.palette.primary[theme.palette.mode]} solid 3px` : 'unset',
            minHeight: 48,
            justifyContent: 'center',
            px: 2.5,
        }}>
            <PriviledgedServiceButton service={service}>
                {button}
            </PriviledgedServiceButton>
        </ListItem>
    );
}
