import { appSchema, tableSchema } from '@nozbe/watermelondb'

export default appSchema({
  version: 1,
  tables: [
    tableSchema({
      name: 'takeoffs',
      columns: [
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
        { name: 'name', type: 'string' },
        { name: 'orientation', type: 'string', },
      ]
    }),
    tableSchema({
      name: 'sites',
      columns: [
        { name: '_id', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },

        { name: 'type', type: 'string' },
        { name: 'provider', type: 'string' },
        { name: 'provider_id', type: 'string' },

        { name: 'name', type: 'string', },
        { name: 'short_name', type: 'string', },
        { name: 'description_text', type: 'string', },
        { name: 'description_wind', type: 'string', },
        { name: 'description_hazards', type: 'string', },
        { name: 'description_restrictions', type: 'string', },

        { name: 'location_type', type: 'string', },
        { name: 'location_coordinates', type: 'string', }, // JSON array
        { name: 'location_longitude', type: 'number', },
        { name: 'location_latitude', type: 'number', },
        { name: 'location_altitude', type: 'number', },
        { name: 'location_city', type: 'string', },
        { name: 'location_post_code', type: 'string', },
        { name: 'location_help', type: 'string', },

        { name: 'wind_conditions_orientation', type: 'string', }, // JSON array
        { name: 'wind_conditions_best', type: 'string', }, // JSON array
        { name: 'wind_conditions_unfriendly', type: 'string', }, // JSON array
        { name: 'wind_conditions_beacon', type: 'string', },

        { name: 'regulatory_information_panel', type: 'string', },
        { name: 'regulatory_aerial_regulation', type: 'string', },

        { name: 'last_modified', type: 'number' },

      ]
    }),
    tableSchema({
      name: 'beacons',
      columns: [
        { name: '_id', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },

        { name: 'provider', type: 'string' },
        { name: 'provider_id', type: 'string' },
        { name: 'name', type: 'string', },
        { name: 'description_text', type: 'string', },
        { name: 'description_type', type: 'string', },

        { name: 'status', type: 'string', },

        { name: 'parameters_time_shift', type: 'number', },
        { name: 'parameters_wind_direction_units', type: 'string', },
        { name: 'parameters_wind_speed_units', type: 'string', },

        { name: 'location_coordinates', type: 'string', }, // JSON array
        { name: 'location_longitude', type: 'number', },
        { name: 'location_latitude', type: 'number', },
        { name: 'location_altitude', type: 'number', },
      ]
    }),
    tableSchema({
      name: 'weatherreports',
      columns: [
        { name: '_id', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'measured_at', type: 'number' },
        { name: 'beacon', type: 'string', },
        { name: 'wind_direction', type: 'number', },
        { name: 'wind_direction_avg', type: 'number', },
        { name: 'wind_speed_avg', type: 'number', },
        { name: 'wind_speed_max', type: 'number', },
        { name: 'wind_speed_min', type: 'number', },
      ]
    }),
    tableSchema({
      name: 'services',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'icon', type: 'string' },
        { name: 'url', type: 'string' },
        { name: 'site_id', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
      ]
    }),
  ]
});
