import React from 'react';
import SitesMapLayer from '../sites/sites-map-layer';
import BeaconsMapLayer from '../beacons/beacons-map-layer';
import { MapInternal } from './with-router-map';

export default function CenteredMap({ latitude, longitude, zoom, ...props }) {

    return (
        <MapInternal {...props} latitude={latitude} longitude={longitude} zoom={zoom}>
            <SitesMapLayer />
            <BeaconsMapLayer />
        </MapInternal>
    )
}
