import { useEffect } from 'react';

export default function BuyMeACoffeeButton(props) {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js';
        script.async = true;

        const config = {
            'data-name': "BMC-Widget",
            'data-cfasync': "false",
            'data-id': "pierrejanko",
            'data-description': "Support me on Buy me a coffee!",
            'data-message': "",
            'data-color': "#5F7FFF",
            'data-position': "Right",
            'data-x_margin': "20",
            'data-y_margin': "20"
        };

        for (let key in config) {
            script.setAttribute(key, config[key]);
        }

        script.onload = function () {
            var evt = document.createEvent('Event');
            evt.initEvent('DOMContentLoaded', false, false);
            window.dispatchEvent(evt);
        }

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            const node = document.getElementById("bmc-wbtn");
            if(node) {
                document.body.removeChild(node);
            }
        }
    }, []);

    return null;
}