import React, { useMemo } from 'react';
import Service from '../service';
import ServicesDrawerLink from '../services-drawer-link';

export const TYPE = 'meteo-parapente';

export function useMeteoParapenteService(site) {
    return useMemo(() => {
        const { locationLongitude, locationLatitude } = site;

        const service = {
            type: TYPE,
            id: '',
            icon: 'https://meteo-parapente.com/img/favicon.png',
            url: `https://meteo-parapente.com/#/${locationLatitude},${locationLongitude},10`,
            name: 'Meteo Parapente',
        }
        return service;
    }, [site])
}

export function MeteoParapenteServiceLink({ site, navigate }) {
    const service = useMeteoParapenteService(site);
    return <ServicesDrawerLink service={service} navigate={navigate} />
}

export default function MeteoParapenteService({ site }) {

    const service = useMeteoParapenteService(site);

    return (
        <Service service={service} />
    );
}
