import { useCallback } from 'react';
import { Model } from '@nozbe/watermelondb'
import { field, date, readonly, relation, } from '@nozbe/watermelondb/decorators'
import { useDatabase } from '@nozbe/watermelondb/hooks'

export const useCreateService = () => {
  const database = useDatabase();
  return useCallback(async ({ name, url, site }) => {
    return await database.write(async () => {
      const service = await database.get('services').create(service => {
        service.name = name;
        service.url = url;
        service.site.set(site);
      });
      return service;
    });
  }, [database]);
}

export const useDeleteService = () => {
  const database = useDatabase();
  return useCallback(async (service) => {
    return await database.write(async () => {
      await service.destroyPermanently();
    });
  }, [database]);
}

export default class Service extends Model {
  static table = 'services'
  static associations = {
    site: { type: 'belongs_to', key: 'site_id' },
  }
 
  @field('name') name
  @field('url') url
  @relation('site', 'site_id') site
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
};
