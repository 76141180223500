import React from 'react';
import { Chip } from '@mui/material';

export default function SiteProviderAttribution({ provider, id }) {
    let attribution = '';
    let url = '';

    switch (provider) {
        case 'ffvl':
            attribution = 'Data kindly provided by FFVL';
            url = `https://federation.ffvl.fr/sites_pratique/voir/${id}`;
            break;
        default:
            break;
    }

    const handleClick = url != null ? () => window.open(url, '_blank') : null;

    if (provider == null) {
        return null;
    }

    return <Chip color='primary' label={attribution} onClick={handleClick} />

}
